<template>
  <div>
    <el-card>
      <el-form ref="mainForm" :model="mainForm.data" :rules="verifyRules.buddyManagerBuddyAdd.mainForm" label-width="150px">
        <el-collapse :value="[1, 2]">
          <el-collapse-item :itle="$t('text.basicInfo')" :name="1">
            <el-row :gutter="12">
              <el-col :md="24" :lg="8">
                <el-form-item :label="$t('text.online')" prop="online">
                  <el-switch v-model="mainForm.data.online"> </el-switch>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <el-form-item :label="$t('form.buddy.supportRefrigeratedProducts')"  prop="support_refrigerated_products">
                  <el-switch v-model="mainForm.data.support_refrigerated_products"> </el-switch>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <el-form-item :label="$t('form.buddy.supportFrozenProducts')" prop="support_frozen_products">
                  <el-switch v-model="mainForm.data.support_frozen_products"> </el-switch>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <el-form-item :label="$t('form.buddy.supportHalalProducts')" prop="support_halal_products">
                  <el-switch v-model="mainForm.data.support_halal_products"> </el-switch>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <el-form-item :label="$t('form.buddy.zeroCommission')" prop="zero_commission">
                  <el-switch v-model="mainForm.data.zero_commission"> </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="12">
              <el-col :md="24" :lg="8">
                <!-- 团长类型 -->
                <el-form-item :label="$t('form.buddy.buddyType')" prop="host_type">
                  <el-select v-model="mainForm.data.host_type" class="width-fill" :placeholder="$t('placeholder.defaultSelect')" clearable filterable>
                    <el-option v-for="(item, index) in HostTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- 团长代码 -->
                <el-form-item :label="$t('form.buddy.buddyCode')">
                  <el-input
                    v-model="mainForm.data.host_code"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- 团长密码 -->
                <el-form-item :label="$t('form.buddy.buddyPSW')">
                  <el-input
                    v-model="mainForm.data.host_password"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- 团长姓名 -->
                <el-form-item :label="$t('form.buddy.buddyName')">
                  <el-input
                    v-model="mainForm.data.name"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="24">
                <!-- 图片 -->
                <el-form-item :label="$t('form.product.image')">
                  <importImg :data="mainForm.fileData.cover" :limit="1" @change="handleMainFormCoverChange" />
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- 手机电话 -->
                <el-form-item :label="$t('form.buddy.mobile')">
                  <el-input
                    v-model="mainForm.data.phone_number"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- WhatsApp号码 -->
                <el-form-item :label="$t('form.buddy.whatsAppNum')">
                  <el-input
                    v-model="mainForm.data.whatsapp_number"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- Whatsapp group link -->
                <el-form-item :label="$t('form.buddy.whatsappGroupLink')">
                  <el-input
                    v-model="mainForm.data.whatsapp_group_link"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- Whatsapp group link -->
                <el-form-item :label="$t('form.buddy.wechatNum')">
                  <el-input
                    v-model="mainForm.data.wechat_id"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="24">
                <!-- IC正面照片 -->
                <el-form-item :label="$t('form.buddy.icFrontPhoto')">
                  <importImg :data="mainForm.fileData.icFront" :limit="1" @change="handleMainFormIcFrontChange" />
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="24">
                <!-- IC背面照片 -->
                <el-form-item :label="$t('form.buddy.icBackPhoto')">
                  <importImg :data="mainForm.fileData.icBack" :limit="1" @change="handleMainFormIcBackChange" />
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="24">
                <!-- 记录 -->
                <el-form-item :label="$t('form.buddy.record')" prop="">
                  <el-input
                    v-model="mainForm.data.note"
                    type="textarea"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
          <el-collapse-item :title="$t('form.buddy.paymentInformation')" :name="2">
            <el-row :gutter="12">
              <el-col :md="24" :lg="8">
                <!-- Bank name -->
                <el-form-item :label="$t('form.buddy.bankName')">
                  <el-select v-model="mainForm.data.bank_name" class="width-fill" :placeholder="$t('placeholder.defaultSelect')" clearable filterable>
                    <el-option v-for="(item, index) in bankNameList" :key="index" :label="item.name" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- Bank swift code -->
                <el-form-item :label="$t('form.buddy.bankSwiftCode')">
                  <el-input
                    v-model="mainForm.data.bank_swift_code"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- Account name -->
                <el-form-item :label="$t('form.buddy.accountName')">
                  <el-input
                    v-model="mainForm.data.account_name"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- Account number -->
                <el-form-item :label="$t('form.buddy.accountNumber')">
                  <el-input
                    v-model="mainForm.data.account_number"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="24" :lg="8">
                <!-- Paynow number -->
                <el-form-item :label="$t('form.buddy.paynowNumber')">
                  <el-input
                    v-model="mainForm.data.paynow_number"
                    :placeholder="$t('placeholder.defaultInput')"
                    :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
        <el-divider></el-divider>
        <el-row type="flex" justify="center">
          <el-button @click="handleMainFormCancelClick">{{ $t('button.cancel') }}</el-button>
          <el-button type="primary" @click="handleMainFormSubmitClick">{{ $t('button.confirm') }}</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Logic from './index.js'
export default Logic
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
